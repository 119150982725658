<template>
	<!-- 	<div style="margin-bottom: 20px;" align="center">
		<ins class="adsbygoogle" style="display:block;" data-ad-client="ca-pub-8147879111198627"
			data-ad-slot="4026586674" data-ad-format="auto" data-full-width-responsive="true"></ins>
	</div> -->
	<!-- 	<div style="margin-bottom: 20px;" align="center">
		<div class="card">
			<h5>
				<Tag class="mr-2" severity="success" value="Deal Records"></Tag>The price will go up with funtion
				upgrading, if you need, buy now! 功能持续更新，价格不断上涨，早买早优惠！<Tag class="mr-2" severity="success" value="成交记录">
				</Tag>
			</h5>
			<Carousel :value="deal_record_data" :numVisible="3" :numScroll="3" :circular="false"
				:responsiveOptions="carouselResponsiveOptions">
				<template #item="product">
					<div class="product-item">
						<div class="product-item-content">
							<div class="mb-3">
								<img :src="'images/dealrecords/' + product.data.image" :alt="product.data.name"
									height="400" />
							</div>
							<div>
								<h4 class="mb-1">
									{{ product.data.name }}
								</h4>
								<Tag style='font-size: 1.875rem;margin-top: 5px;margin-bottom: 5px;' class="mr-2"
									severity="info" :value="product.data.price"></Tag>
								<Rating :modelValue="product.data.rating" :readonly="true" :cancel="false" class="mb-2">
								</Rating>
							</div>
						</div>
					</div>
				</template>
			</Carousel>
		</div>
	</div> -->
	<Sidebar v-model:visible="visibleFull" :baseZIndex="1000" position="full">
		<video autoplay controls :src="current_video_src" style="width:100%;" />
	</Sidebar>
	<div class="card grid" style="justify-content: space-around;padding: 0px;">
		<!-- <div class="card col-12 md:col-12"> -->
		<div class="col-12 md:col-8 p-3">
			<div v-if='show_client_map'>
				<!-- <img src="images/5.jpg" style='width: 100%;' alt="" /> -->
				<iframe ref='client_map' style='padding:0px;margin-bottom: -30px;' name="client_map" id="client_map"
					:src="'client_map/world_map.html?height='+client_map_height" frameborder="0" width="100%"
					:height='client_map_height+"px"'></iframe>
			</div>
		</div>
		<div id='info_container' class="col-12 md:col-4">
			<div class='shadow-2 surface-card' style="margin-top: 10px;border-radius: 5px;">
				<AdminInfo></AdminInfo>
				<div class="p-3 h-full">
					<Button v-if='language' @click='showDemo()' style="width: 100%;"
						label="Pull down the page or click me to try Live Demo" icon="pi pi-arrow-down"
						class="p-button-help" />
					<Button v-if='!language' @click='showDemo()' style="width: 100%;" label="下拉页面或点我体验在线 Demo"
						icon="pi pi-arrow-down" class="p-button-help" />
				</div>
			</div>
			<div style="margin-top: 10px;padding: 0px;">
				<Payme></Payme>
			</div>

		</div>
	</div>
	<div class="card grid" style="justify-content: space-around;padding: 0px;margin-top: 20px;">
		<div v-if='!language' class="col-12 md:col-2 p-3">
			<Button @click='goToTutorialVideo("zh_cn")' style="width: 100%;" label="操作视频教程" icon="pi pi-youtube"
				class="p-button-help p-button-outlined mb-3" />
		</div>
		<div v-if='!language' class="col-12 md:col-10 p-3">
			<a title='点我试用并了解产品详情' href="https://www.elasticpdf.com/demo" target="_blank">
				<Button style="width: 100%;" icon="pi pi-arrow-right" iconPos="right"
					label="如需像 Adobe Acrobot 保存标准批注和注释文本到 PDF 中, 欢迎使用我们的专业产品 ElasticPDF"
					class="p-button-help mb-3" />
			</a>
		</div>
		<div v-if='language' class="col-12 md:col-2 p-3" style="margin-bottom: -30px;">
			<Button @click='goToTutorialVideo("en")' style="width: 100%;" label="Tutorial Video" icon="pi pi-youtube"
				class="p-button-danger p-button-outlined mb-3" />
		</div>
		
		<div v-if='language' class="col-12 md:col-10 p-3">
			<a title='click to learn more and try' href="https://www.elasticpdf.com/demo" target="_blank">
				<Button style="width: 100%;" icon="pi pi-arrow-right" iconPos="right"
					label="If you need to save standard annotation and comments text into PDF like Adobe Acrobat, welcome to try our professional product ElasticPDF"
					class="p-button-help mb-3" />
			</a>
		</div>
		<div class="col-12 md:col-10" style="margin-top: -30px;">
			<iframe ref='pdfjs_viewer_1' name="pdfjs_container" id="pdfjs_container"
				src='pdfjs-legacy-latest/web/test.html' frameborder="0" width="100%" :height="pdf_height"></iframe>
		</div>
		<div class="col-12 md:col-2 tutorial-div" :style="'height:'+tutorial_height">
			<h5 v-if='!language' style="color: #8f48d2;font-weight: 800;">视频教程列表</h5>
			<h5 v-if='language'  style="color: #8f48d2;font-weight: 800;">Tutorial videos</h5>
			<div v-for='(t_item,index) in tutorial_videos' :key='index'>
				<div v-if='index%5==0'
					style="width: 100%;border-top: 1px solid #ddd;margin-top: -3px;margin-bottom: 10px;"></div>
				<Button @click="(event) => showVideoOverlay(event,t_item)" :label='(index+1)+"-"+t_item.title'
					icon='pi pi-video' :class='t_item.class+" p-button-rounded p-button-outlined mb-3"'
					style="padding: 8px 15px 8px 15px;"></Button>
			</div>
			<OverlayPanel ref="tutorial_video" appendTo="body" :showCloseIcon="true">
				<video autoplay controls :src="current_video_src" style="width:700px;" />
			</OverlayPanel>
		</div>
	</div>
</template>

<script>
	//从相对路径引入 NetWorkService.js
	// import GoogleAds from './GoogleAds.vue';
	import AdminInfo from './AdminInfo.vue';
	// import DealRecords from './DealRecords.vue';
	import ReadFileService from '../service/ReadFileService.js';
	import Payme from './Payme.vue';
	export default {
		name: '',
		components: {
			// 'GoogleAds': GoogleAds,
			'AdminInfo': AdminInfo,
			// 'DealRecords': DealRecords,
			'Payme': Payme,
		},
		data() {
			return {
				// pdf_path: 'tutorial.pdf',
				pdf_path: 'tutorial.pdf',
				pdf_height: "720px",
				tutorial_height:"680px",
				client_map_height: "620px",
				show_client_map: true,
				visibleFull: false,
				pdf_url: null,
				carouselResponsiveOptions: [{
						breakpoint: "1024px",
						numVisible: 3,
						numScroll: 3,
					},
					{
						breakpoint: "768px",
						numVisible: 2,
						numScroll: 2,
					},
					{
						breakpoint: "560px",
						numVisible: 1,
						numScroll: 1,
					},
				],
				deal_record_data: [{
					"id": "1001",
					"code": "f230fh0g4",
					"name": "Pierpaolo* from Italy",
					"description": "Bought source code",
					"image": "Pierpaolo.jpg",
					"price": "$ 200",
					"category": "Accessories",
					"quantity": 24,
					"inventoryStatus": "INSTOCK",
					"rating": 5
				}, {
					"id": "1002",
					"code": "f230fh0g5",
					"name": "Zack.Fair from China",
					"description": "Bought source code",
					"image": "Zack Fair.jpg",
					"price": "￥999",
					"category": "Accessories",
					"quantity": 24,
					"inventoryStatus": "INSTOCK",
					"rating": 5
				}, {
					"id": "1000",
					"code": "f230fh0g3",
					"name": "98 && is a rainbow from China",
					"description": "Bought encrypted code",
					"image": "98_is.jpg",
					"price": "￥66+66",
					"category": "Accessories",
					"quantity": 24,
					"inventoryStatus": "INSTOCK",
					"rating": 5
				}, {
					"id": "1002",
					"code": "f230fh0g5",
					"name": "Pranjul* from India",
					"description": "Bought encrypted code",
					"image": "Pranjul.jpg",
					"price": "$ 9.99",
					"category": "Accessories",
					"quantity": 24,
					"inventoryStatus": "INSTOCK",
					"rating": 5
				}],
				video: {
					"en": "???????",
					"zh_cn": "???????"
				},
				current_video_src: '/operation-videos/1-highlight.mp4',
			}
		},
		// networkService: null,
		created() {
			//实例化NetWorkService对象
			this.ReadFileService = new ReadFileService();
		},
		mounted() {
			// window.addAds();
			// window.addAds();
			// this.createTutorialButtons();
			let vm = this;
			vm.resizeWin();
			// //屏幕变化时
			window.onresize = () => {
				vm.show_client_map = false;
				setTimeout(function() {
					vm.show_client_map = true;
				}, 500);
				vm.resizeWin();
				// return (() => {
				// 	vm.resizeWin();
				// })();
			};

			// this.majorService.getMajorsNameAndId().then(data => {
			// 	this.majors = data;
			// });
			var client_map_container = document.getElementById('info_container');
			// var try_demo_button=document.getElementById('try_demo_button');
			this.client_map_height = client_map_container.offsetHeight;
			console.log('client_map_container', this.client_map_height);
			this.ReadFileService.getUpdateInfo().then(data => {
				this.video = data.video;
			});
		},
		methods: {
			//屏幕变化调整样式
			resizeWin() {
				// this.pdf_width = document.documentElement.scrollWidth-400+'px';
				var windowHeight = document.documentElement.clientHeight;
				if (windowHeight > 830) {
					this.pdf_height = '750px';
					this.pdf_height = '710px';
				} else {
					this.pdf_height = windowHeight - 50 + 'px';
					this.tutorial_height=windowHeight - 90 + 'px';
				}
				// console.log('高度',this.pdf_height);
			},
			goToTutorialVideo(para) {
				window.open(this.video[para], '_blank');
			},
			addPdfUrl() {
				if (this.pdf_url) {
					this.pdf_path = this.pdf_url;
				} else {
					alert('Please input the correct pdf URL 请输入正确PDF文件的URL');
				}
			},
			goToElasticPDF() {
				window.open('https://www.elasticpdf.com/demo', '_blank');
			},
			showDemo() {
				var pdfjs_container_id = "pdfjs_container";
				var pdfjs_container = document.getElementById(pdfjs_container_id);
				pdfjs_container.scrollIntoView();
				var currentScroll = window.scrollY;

				// 设置滚动目标位置
				var targetScroll = currentScroll - 80;

				// 使用平滑滚动来滚动到目标位置
				window.scrollTo({
					top: targetScroll,
					behavior: 'smooth'
				});
			},

			showVideoOverlay(event, video_item) {
				console.log('点击视频', event, video_item);
				this.current_video_src = video_item.video;
				this.$refs.tutorial_video.toggle(event);
			},
			enterFullScreen() {
				this.visibleFull = true;
				console.log('视频进入全屏');
				// var url='https://demos.libertynlp.com/'+this.current_video_src;
				// window.open(url, "_blank")
			},
		},
		computed: {
			// language() {
			// 	if (this.$appState.language=='en'){
			// 		// this.$refs.pdfjs_viewer_1.contentWindow.setLanguage('en');
			// 		console.log(this);
			// 		return true;
			// 	}else{
			// 		// this.$refs.pdfjs_viewer_1.contentWindow.setLanguage('zh-cn');
			// 		console.log(this);
			// 		return false;
			// 	}
			// }
			language() {
				if (this.$appState.language == 'en') {
					return true;
				} else {
					return false;
				}
			},
			tutorial_videos() {
				if (this.$appState.language == 'en') {
					return [{
							'video': '/operation-videos/1-highlight.mp4',
							'viewed': false,
							'class': 'p-button-help',
							'title': 'Highlight',
							'description': 'Whether on desktop, pad or mobile, text can be precisely selected, supporting cross-page highlighting. After highlighting, the annotation can be modified for color and other attributes, achieving a perfect highlighting function on the web.'
						},
						{
							'video': '/operation-videos/2-underline.mp4',
							'viewed': false,
							'class': 'p-button-help',
							'title': 'Underline',
							'description': 'Similar to the highlight feature, it enables precise text selection on multiple devices. Once the annotation is created, the color and line width of the underline can be modified.'
						},
						{
							'video': '/operation-videos/13-strikeout.mp4',
							'viewed': false,
							'class': 'p-button-help',
							'title': 'Strikeout',
							'description': 'Users are able to select the text to be marked with a mouse or touch, and then add strikethrough to the text. It supports cross-page when selecting text'
						},
						{
							'video': '/operation-videos/15-signature.mp4',
							'viewed': false,
							'class': 'p-button-help',
							'title': 'Signature',
							'description': 'Signature feature supports handwritten signatures, type signatures, and uploading signature files'
						},
						{
							'video': '/operation-videos/5-brush.mp4',
							'viewed': false,
							'class': 'p-button-help',
							'title': 'Brush',
							'description': 'The brush size, color, and opacity can be adjusted, allowing for drawing on the document freely. Lower opacity settings can be used to highlight scanned text.'
						},
						{
							'video': '/operation-videos/6-add-text.mp4',
							'viewed': false,
							'title': 'Add Text',
							'class': 'p-button-success',
							'description': 'Text annotations can be created by inserting or dragging, supporting all languages. Once created, the text shape, font size, and other attributes can be modified.'
						},
						{
							'video': '/operation-videos/7-polygon.mp4',
							'viewed': false,
							'title': 'Polygon',
							'class': 'p-button-success',
							'description': 'Supports the creation of arrows, lines, rectangles, circles (ellipses), checkmarks, and identity stamps. The generated annotation can be modified in terms of color, size, position, and orientation.'
						},
						{
							'video': '/operation-videos/3-insert-image.mp4',
							'viewed': false,
							'class': 'p-button-success',
							'title': 'Insert Image',
							'description': 'Images can be inserted by selecting files or dragging in. Afterward, the size, position, orientation, and opacity of the image can be smoothly modified.'
						},
						{
							'video': '/operation-videos/8-hyperlink.mp4',
							'viewed': false,
							'class': 'p-button-success',
							'title': 'Hyperlink',
							'description': 'By drawing a rectangle or selecting text, a hyperlink object can be created, linking to a URL, page number, online document, and more.'
						},
						{
							'video': '/operation-videos/9-eraser.mp4',
							'viewed': false,
							'title': 'Eraser',
							'class': 'p-button-success',
							'description': 'A powerful eraser function allows partial erasure of annotation objects, with visible erasure traces. Erased text remains editable.'
						},
						{
							'video': '/operation-videos/4-screen-shot.mp4',
							'viewed': false,
							'title': 'Screen Shot',
							'class': 'p-button-warning',
							'description': 'After drawing a rectangle, a screenshot of the current document can be captured. The position of the rectangle can be adjusted for further screenshots.'
						},
						{
							'video': '/operation-videos/10-undo-redo.mp4',
							'viewed': false,
							'class': 'p-button-warning',
							'title': 'Undo & Redo Annotations',
							'description': 'Annotations, including creation, modifications, various edits, and deletions, can be undone or redone within 10 steps.'
						},
						{
							'video': '/operation-videos/11-output-input-annotation.mp4',
							'viewed': false,
							'class': 'p-button-warning',
							'title': 'Output & Import JSON Annotations',
							'description': 'Supports exporting annotated documents as JSON files, which can later be reloaded for perfect re-display, making it an ideal solution for multi-device synchronization.'
						},
						{
							'video': '/operation-videos/12-connect-line-language.mp4',
							'viewed': false,
							'class': 'p-button-warning',
							'title': 'Connect Line and Multi-language',
							'description': 'Connects annotations in the right-side list to graphic objects on the page, with separate control for each page. All tips support both Chinese and English.'
						}
					];
				} else {
					return [{
							'video': '/operation-videos/1-highlight.mp4',
							'viewed': false,
							'class': 'p-button-help',
							'title': '文本高亮',
							'description': '无论是桌面端、平板端或者手机都可以精确选择文本，支持跨页高亮，高亮后内容可以修改颜色等属性，在 Web 端实现了完美的高亮标记功能',
						},
						{
							'video': '/operation-videos/2-underline.mp4',
							'viewed': false,
							'class': 'p-button-help',
							'title': '文本下划线',
							'description': '与高亮功能类似，实现了多种设备文本的精确选择，批注生成后可以修改批注颜色、线条粗细等'
						},
						{
							'video': '/operation-videos/13-strikeout.mp4',
							'viewed': false,
							'class': 'p-button-help',
							'title': '删除线',
							'description': '支持通过鼠标或者触屏来选择要标记的文本，之后为这些文本添加删除线，选择文本时支持跨页操作'
						},
						{
							'video': '/operation-videos/15-signature.mp4',
							'viewed': false,
							'class': 'p-button-help',
							'title': '签名',
							'description': '支持用户手写签名、输入签名（可切换颜色，字体）和上传用户的签名文件'
						},
						{
							'video': '/operation-videos/3-insert-image.mp4',
							'viewed': false,
							'title': '插入图片',
							'class': 'p-button-help',
							'description': '支持以选取文件或者拖入的方式来插入图片，之后可以对图片的大小、位置、方位、不透明度等实现流畅的修改'
						},
						{
							'video': '/operation-videos/4-screen-shot.mp4',
							'viewed': false,
							'class': 'p-button-success',
							'title': '屏幕截图',
							'description': '绘制矩形框后可截取当前文档的部分内容，并可以修改矩形框位置后再次截图'
						},
						{
							'video': '/operation-videos/5-brush.mp4',
							'viewed': false,
							'class': 'p-button-success',
							'title': '自由绘制',
							'description': '可以随意调整笔刷的大小、颜色和不透明度，然后在文档上自由的绘制，在设置不透明度较小时还可以用来高亮扫描件文本'
						},
						{
							'video': '/operation-videos/6-add-text.mp4',
							'viewed': false,
							'class': 'p-button-success',
							'title': '添加文本',
							'description': '可以以插入和拖入的方式来创建文本批注，支持所有语言，创建后可以拖动的方式修改字体形状、字体大小等'
						},
						{
							'video': '/operation-videos/7-polygon.mp4',
							'viewed': false,
							'class': 'p-button-success',
							'title': '多边形',
							'description': '支持创建箭头、直线、矩形、圆形（椭圆）、对勾和身份印章等多边形，生成后的批注支持颜色、大小、位置方位等的修改'
						},
						{
							'video': '/operation-videos/8-hyperlink.mp4',
							'viewed': false,
							'class': 'p-button-success',
							'title': '超链接',
							'description': '通过绘制矩形框或者选取文字后创建超链接对象，之后可以链接到 URL、页码、在线文档等'
						},
						{
							'video': '/operation-videos/9-eraser.mp4',
							'viewed': false,
							'class': 'p-button-warning',
							'title': '橡皮擦',
							'description': '超强橡皮擦功能，可以局部擦除批注对象，擦除过程中轨迹可见，且擦除后的文本仍然可编辑'
						},
						{
							'video': '/operation-videos/10-undo-redo.mp4',
							'viewed': false,
							'class': 'p-button-warning',
							'title': '撤销 & 重做 批注',
							'description': '可以在 10 步以内撤销或者重做批注，包括批注的生成、修改等各类编辑以及删除等操作'
						},
						{
							'video': '/operation-videos/11-output-input-annotation.mp4',
							'viewed': false,
							'class': 'p-button-warning',
							'title': '导出 & 导入 JSON 批注',
							'description': '支持将批注文档导出为 JSON 文件，之后再次加载 JSON 文件实现完美的回显，是多端同步的最佳方案'
						},
						{
							'video': '/operation-videos/12-connect-line-language.mp4',
							'viewed': false,
							'class': 'p-button-warning',
							'title': '批注连接线 & 双语',
							'description': '将右侧列表中的批注与页面的图形对象相连，每一页都单独控制；所有页面操作都支持中英文双语'
						}
					];
				}
			},

		}
	}
</script>

<style scoped lang="scss">
	video::-webkit-media-controls-fullscreen-button {
		display: none !important;
	}

	.tutorial-div {
		background-color: aliceblue;
		margin-top: -20px;
		padding: 10px;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		overflow: auto;
		height: 500px;
	}

	button {
		padding: 10px 15px 10px 15px;
		cursor: pointer;
	}
</style>